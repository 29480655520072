import React, { useState } from "react";
import { Link, Element } from "react-scroll";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Home.scss";
import Header from "../components/Header";
import videoSrc from "../assets/video/banner_video.mp4";
import FbIcon from "../assets/icons/FbIcon";
import InIcon from "../assets/icons/InIcon";
import aboutImage from "../assets/images/about-img.png";
import whyWeImage from "../assets/images/whyWe-img.png";
import HomeService from "../components/HomeService";
import posterSrc from "../assets/video/banner_video_bg.jpg";
import MailIcon from "../assets/icons/MailIcon";
import PhoneIcon from "../assets/icons/PhoneIcon";
import map from "../assets/images/map.png";
import Footer from "../components/Footer";
import styleImage1 from "../assets/images/img-1.png";
import InstaIcon from "../assets/icons/InstaIcon";
import { submitContactUs } from "../services/contactusService";
import { PulseLoader  } from "react-spinners";
import XIcon from "../assets/icons/XIcon";

const Home = () => {
  // Loader visibility
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
    });
  };

  const notifyFailure = (errorMessage) => {
    toast.error(errorMessage, {
      position: "bottom-center",
      autoClose: 3000,
    });
  };

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [submitError, setSubmitError] = useState(""); // For general form submission error

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "fullName":
        error =
          value.length < 3 ? "Full Name must be at least 3 characters" : "";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        error = !emailRegex.test(value) ? "Invalid email format" : "";
        break;
      case "phoneNumber":
        const phoneRegex = /^[0-9]{10}$/;
        error = !phoneRegex.test(value) ? "Phone Number must be 10 digits" : "";
        break;
      case "subject":
        error = value.length < 3 ? "Subject must be at least 3 characters" : "";
        break;
      case "message":
        error =
          value.length < 10 ? "Message must be at least 10 characters" : "";
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any field is empty
    const hasEmptyFields = Object.values(formData).some((field) => !field);

    // Check if any errors exist
    const hasErrors = Object.values(errors).some((error) => error);

    if (!hasErrors && !hasEmptyFields) {
      // Clear any previous error message
      setSubmitError("");

      // Show loader when submission starts
    setIsVisible(true);

      // Form is valid, proceed with submission
      console.log("Form submitted successfully:", formData);

      //call the contactus api
      try {
        const data = await submitContactUs({
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phoneNumber,
          subject: formData.subject,
          message: formData.message,
        });

        console.log("Success!", data);

        //everything went well, now clear the fields
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });

        notifySuccess(
          "Thanks for contacting us, We will reachout to you shortly!."
        );
      } catch (error) {
        console.error(error.toJSON());
        if (error.code == "ERR_BAD_REQUEST") {
          notifyFailure("One or more required fields are missing or invalid.");
        } else if (error.code == "ERR_NETWORK") {
          notifyFailure(
            "Network error, please check your internet connection."
          );
        } else {
          notifyFailure(
            "An internal error occurred, Unable to submit your message"
          );
        }
      }
      finally {
        // Hide loader after submission is complete
        setIsVisible(false);
      }
    } else {
      // If there are empty fields or errors, display a general error message
      setSubmitError("Please fill out all required fields correctly.");
      console.log("Form contains errors or incomplete fields");
    }
  };

  return (
    <div>
      <Header />

      {/* Banner Section */}
      <Element name="section1">
        <div className="banner-container">
          <div className="video-wrapper">
            <video
              className="video"
              src={videoSrc}
              autoPlay
              loop
              muted
              playsInline
              poster={posterSrc}
              aria-label="Company introduction video"
            />
          </div>
          <div className="banner-content">
            <div className="container">
              <div className="banner-text">
                Passion Brings Out
                <span>The Best In Us,</span>
                To Bring Out The Best For You.
              </div>
              <Link
                className="banner-btn"
                to="section7"
                spy={true}
                smooth={true}
                duration={500}
                role="button"
                aria-label="Scroll to Contact Us section"
              >
                Contact Now
              </Link>
            </div>
          </div>
          <div className="social-media">
            <a
              href="https://www.facebook.com/people/Aquodio-Technologies-LLP/61562040320278/"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
              aria-label="Visit our Facebook page"
            >
              <FbIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/aquodio/?viewAsMember=true"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
              aria-label="Visit our LinkedIn profile"
            >
              <InIcon />
            </a>
            <a
              href="https://www.instagram.com/aquodio/"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
              aria-label="Visit our Instagram profile"
            >
              <InstaIcon />
            </a>
            <a
              href="https://x.com/aquodio"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
              aria-label="Visit our X profile"
            >
              <XIcon/>
            </a>
          </div>
        </div>
      </Element>

      {/* About Us Section */}
      <Element name="section2">
        <section className="about-section">
          <div className="container">
            <div className="section-row">
              <div className="section-col">
                <img
                  className="img-fluid"
                  src={aboutImage}
                  alt="Team working together"
                />
              </div>
              <div
                className="section-col"
                role="region"
                aria-labelledby="about-us-heading"
              >
                {/* <h3>Who we are..</h3> */}
                <h1>About Us</h1>
                <p>
                  We are trailblazers in crafting software solutions that propel
                  business forward. We make software products with a focus on
                  innovation driven by a passion for excellence. Our products
                  are built around awesome user experience and creative ideas
                  that matter. We are here to revolutionize the way you do
                  business.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* Why We Section */}
      <Element name="section3">
        <section className="whyWe-section">
          <div className="style-image1">
            <img className="img-fluid" src={styleImage1} alt="" />
          </div>
          <div className="container">
            <div className="section-row">
              <div className="section-col">
                <img
                  className="img-fluid"
                  src={whyWeImage}
                  alt="Why we work with passion"
                />
              </div>
              <div className="section-col">
                <h1>Why We Do It</h1>
                <p>
                  Our purpose is to ignite quantum change in the way the world
                  does their business, by creating innovative software products
                  that transform the business process.
                </p>
                <p>
                  Our core values of innovation, passion and excellence are at
                  the heart and soul of every software product we develop.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* Services Section */}
      <Element name="section4">
        <section className="services-section">
          <HomeService />
        </section>
      </Element>

      {/* Contact Us Section */}
      <Element name="section7">
        <section className="contact-section">
          <div className="container">
            <div className="section-row">
              <div className="section-col">
                <div className="contact">
                  <h1>Contact Us</h1>
                  <p>
                    Feel Free to connect with us through our online channels.
                  </p>
                  <form className="contact-form">
                    {/* Display submission error if form is incomplete */}
                    {submitError && (
                      <div className="all-text-danger">{submitError}</div>
                    )}
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <label htmlFor="fullName">
                          Full Name<span className="asterisk">*</span>
                        </label>
                        <div className="input-wrap">
                          <input
                            id="fullName"
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            aria-required="true"
                            aria-invalid={errors.fullName ? "true" : "false"}
                          />
                          {errors.fullName && (
                            <small className="text-danger" role="alert">
                              {errors.fullName}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <label htmlFor="email">
                          Email Address<span className="asterisk">*</span>
                        </label>
                        <div className="input-wrap">
                          <input
                            id="email"
                            type="text"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            aria-required="true"
                            aria-invalid={errors.email ? "true" : "false"}
                          />
                          {errors.email && (
                            <small className="text-danger" role="alert">
                              {errors.email}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <label htmlFor="phoneNumber">
                          Phone Number<span className="asterisk">*</span>
                        </label>
                        <div className="input-wrap">
                          <input
                            id="phoneNumber"
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            aria-required="true"
                            aria-invalid={errors.phoneNumber ? "true" : "false"}
                          />
                          {errors.phoneNumber && (
                            <small className="text-danger" role="alert">
                              {errors.phoneNumber}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <label htmlFor="subject">
                          Subject<span className="asterisk">*</span>
                        </label>
                        <div className="input-wrap">
                          <input
                            id="subject"
                            type="text"
                            className="form-control"
                            placeholder="Enter Keyword"
                            name="subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                            aria-required="true"
                            aria-invalid={errors.subject ? "true" : "false"}
                          />
                          {errors.subject && (
                            <small className="text-danger" role="alert">
                              {errors.subject}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label htmlFor="message">
                          Your Message<span className="asterisk">*</span>
                        </label>
                        <div className="input-wrap">
                          <textarea
                            id="message"
                            className="form-control"
                            rows="4"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            aria-required="true"
                            aria-describedby="messageHelp"
                            aria-invalid={errors.message ? "true" : "false"}
                          />
                          {errors.message && (
                            <small className="text-danger" role="alert">
                              {errors.message}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="button-wrap">
                      <button
                        className="primary-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Send Message
                      </button>
                      {isVisible && (
                        <div className="loader">
                          <PulseLoader  color="#ffffff" size={10} />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className="section-col">
                <div className="address-wrap">
                  <div className="address-left">
                    <div className="country">India</div>
                    <div className="address">
                      <span>GCRA - 185A</span>
                      <span>Changampuzha Nagar</span>
                      <span>Ernakulam - 682033</span>
                    </div>
                  </div>
                  <div className="contacts">
                    <ul>
                      <li aria-label="Our contact phone number">
                        <span>
                          <PhoneIcon />
                        </span>
                        +91 7994022030
                      </li>
                      <li aria-label="Email us at">
                        <span>
                          <MailIcon />
                        </span>
                        <a href="mailto: info@aquodio.com">
                          {" "}
                          info@aquodio.com{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="map">
                  <img
                    className="img-fluid"
                    src={map}
                    alt="Location map of our office"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <Footer />
      <ToastContainer aria-live="polite" />
    </div>
  );
};

export default Home;
