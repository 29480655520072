import React from "react";
import "./Footer.scss";
import footerLogo from "../assets/images/logo-footer.svg";
import FbIcon from "../assets/icons/FbIcon";
import InIcon from "../assets/icons/InIcon";
import MailIcon from "../assets/icons/MailIcon";
import PhoneIcon from "../assets/icons/PhoneIcon";
import InstaIcon from "../assets/icons/InstaIcon";
import XIcon from "../assets/icons/XIcon";

const Footer = () => {
  return (
    <footer className="footer" aria-label="Footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="logo-section">
                <div className="logo">
                  <img
                    className="img-fluid"
                    src={footerLogo}
                    alt="Aquodio logo"
                  />
                </div>
                <p>Fuelled by Passion, Driven by Innovation.</p>
                <div className="social-media" aria-label="Social media links">
                  <a
                    href="https://www.facebook.com/people/Aquodio-Technologies-LLP/61562040320278/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                    aria-label="Visit Aquodio on Facebook"
                  >
                    <FbIcon />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/aquodio/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                    aria-label="Visit Aquodio on LinkedIn"
                  >
                    <InIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/aquodio/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                    aria-label="Visit Aquodio on Instagram"
                  >
                    <InstaIcon />
                  </a>
                  <a
                    href="https://x.com/aquodio"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon"
                    aria-label="Visit Aquodio on X"
                  >
                    <XIcon/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-9 service-link-section">
              <div className="service-link-wrap">
                <h2>Services</h2>
                <div className="service-link">
                  <ul>
                    <li>
                      <a href="#" aria-label="Cloud Computing service">
                        Cloud Computing
                      </a>
                    </li>
                    <li>
                      <a href="#" aria-label="Desktop Apps service">
                        Desktop Apps
                      </a>
                    </li>
                    <li>
                      <a href="#" aria-label="Mobile App Development service">
                        Mobile App Development
                      </a>
                    </li>
                    <li>
                      <a href="#" aria-label="Data Science service">
                        Data Science
                      </a>
                    </li>
                    <li>
                      <a href="#" aria-label="Web App Development service">
                        Web App Development
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#" aria-label="IoT App Development service">
                        IoT App Development
                      </a>
                    </li>
                    <li>
                      <a href="#" aria-label="Custom Web App service">
                        Custom Web App
                      </a>
                    </li>
                    <li>
                      <a href="#" aria-label="UI/UX Design service">
                        UI/UX Design
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        aria-label="React Native App Development service"
                      >
                        React Native App Development
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="get-in-touch">
                <h2>Get In Touch</h2>
                <div className="contacts">
                  <ul>
                    <li>
                      <span>
                        <PhoneIcon />
                      </span>
                      +91 7994022030
                    </li>
                    <li>
                      <span>
                        <MailIcon />
                      </span>
                      <a
                        href="mailto:info@aquodio.com"
                        aria-label="Email Aquodio at info@aquodio.com"
                      >
                        {" "}
                        info@aquodio.com{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        © Copyrights 2024 Aquodio Technologies LLP All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
