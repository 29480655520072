import React from "react";

function XIcon() {
  return (
    <>
      <svg width="15.566" height="18" viewBox="0 0 15.566 18">
        <g id="Group_69" data-name="Group 69" transform="translate(-3 -3)">
          <path
            id="Path_16"
            data-name="Path 16"
            d="M12.594,23.258l-.012,0-.071.035-.02,0-.014,0-.071-.036a.022.022,0,0,0-.024.006l0,.01-.017.428.005.02.01.013.1.074.015,0,.012,0,.1-.074.012-.016,0-.017-.017-.427q0-.016-.016-.018m.264-.113-.014,0-.184.093-.01.01,0,.011.018.43.005.012.008.008.2.092a.025.025,0,0,0,.029-.008l0-.014-.034-.614a.026.026,0,0,0-.02-.022m-.715,0a.02.02,0,0,0-.027.006l-.006.014-.034.614a.025.025,0,0,0,.017.024l.015,0,.2-.093.01-.008,0-.011.018-.43,0-.012-.01-.01Z"
            transform="translate(-1.287 -2.814)"
            fill="none"
            fill-rule="evenodd"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M17.488,4.4a.83.83,0,0,0-.082-1.191.88.88,0,0,0-1.221.08L11.767,8.216,8.016,3.337A.872.872,0,0,0,7.324,3H3.865a.867.867,0,0,0-.774.466.827.827,0,0,0,.082.884L8.74,11.59l-4.661,5.2a.83.83,0,0,0,.082,1.191.88.88,0,0,0,1.221-.08L9.8,12.971l3.751,4.878a.872.872,0,0,0,.692.337H17.7a.867.867,0,0,0,.774-.466.827.827,0,0,0-.082-.884L12.827,9.6l4.661-5.2ZM14.675,16.5,5.594,4.687h1.3L15.972,16.5Z"
            fill="#1778be"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </>
  );
}

export default XIcon;
